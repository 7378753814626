export const LOAD_COLLECTED_CO_REGISTRATION_EXCEL = 'LOAD_COLLECTED_CO_REGISTRATION_EXCEL'
export const COLLECTED_CO_REGISTRATION_EXCEL_LOADING = 'COLLECTED_CO_REGISTRATION_EXCEL_LOADING'
export const COLLECTED_CO_REGISTRATION_EXCEL_LOADING_SUCCESS = 'COLLECTED_CO_REGISTRATION_EXCEL_LOADING_SUCCESS'
export const COLLECTED_CO_REGISTRATION_EXCEL_LOADING_ERROR = 'COLLECTED_CO_REGISTRATION_EXCEL_LOADING_ERROR'
export const COLLECTED_CO_REGISTRATION_EXCEL_INVALID = 'COLLECTED_CO_REGISTRATION_EXCEL_INVALID'

export const SAVE_COLLECTED_CO_REGISTRATION_EXCEL = 'SAVE_COLLECTED_CO_REGISTRATION_EXCEL'
export const COLLECTED_CO_REGISTRATION_EXCEL_SAVING = 'COLLECTED_CO_REGISTRATION_EXCEL_SAVING'
export const COLLECTED_CO_REGISTRATION_EXCEL_SAVING_SUCCESS = 'COLLECTED_CO_REGISTRATION_EXCEL_SAVING_SUCCESS'
export const COLLECTED_CO_REGISTRATION_EXCEL_SAVING_ERROR = 'COLLECTED_CO_REGISTRATION_EXCEL_SAVING_ERROR'
