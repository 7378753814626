import { render, staticRenderFns } from "./CollectedDemographyLeads.vue?vue&type=template&id=ee8ce754&scoped=true&"
import script from "./CollectedDemographyLeads.vue?vue&type=script&lang=js&"
export * from "./CollectedDemographyLeads.vue?vue&type=script&lang=js&"
import style0 from "./CollectedDemographyLeads.vue?vue&type=style&index=0&id=ee8ce754&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee8ce754",
  null
  
)

export default component.exports