import * as priv from "./privileges.js";
import {PRIV_COLLECTED_DEMOGRPHY, PRIV_COLLECTED_LEADS, PRIV_READ_MAILING_EFFINCIENCY_CONST} from "./privileges.js";

export default [
  {
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    path: "/dashboard",
  },
  {
    name: "Administracja",
    icon: "nc-icon nc-laptop",
    privileges: [priv.PRIV_READ_ADMIN, priv.PRIV_EDIT_ADMIN],
    children: [
      {
        name: "Użytkownicy",
        path: "/admin/users",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Role",
        path: "/admin/roles",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Uprawnienia",
        path: "/admin/permissions",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Zakresy",
        path: "/admin/scopes",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Systemy",
        path: "/admin/systems",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Endpointy",
        path: "/admin/endpoints",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Partnerzy C2S",
        path: "/admin/partners",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Bazy danych",
        path: "/admin/databases",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Grupy reklamacyjne",
        path: "/admin/complaints_groups",
        privileges: [priv.PRIV_READ_ADMIN],
      },
      {
        name: "Parametry systemowe",
        path: "/admin/system_params",
        privileges: [priv.PRIV_READ_ADMIN],
      },
    ],
  },
  {
    name: "Pytania",
    icon: "fa fa-question",
    privileges: [priv.PRIV_READ_ADMIN_QUESTIONS],
    children: [
      {
        name: "Pytania",
        path: "/questions/questions",
        privileges: [priv.PRIV_READ_ADMIN_QUESTIONS],
      },
      {
        name: "Kolejność",
        path: "/questions/order",
        privileges: [priv.PRIV_READ_ADMIN_QUESTIONS],
      },
      {
        name: "Konfiguracja",
        path: "/questions/configuration",
        privileges: [priv.PRIV_READ_ADMIN_QUESTIONS_CONFIG],
      },
      {
        name: "Grupy pytań",
        path: "/questions/question_group",
        privileges: [priv.PRIV_READ_QUESTION_GROUP],
      },
      {
        name: "Nagrody",
        path: "/questions/prizes",
        privileges: [priv.PRIV_READ_PRIZES],
      },
    ],
  },
  {
    name: "Słowniki",
    icon: "fa fa-book",
    privileges: [priv.PRIV_READ_CATEGORY_DICT, priv.PRIV_READ_POSTCODES_DICT, priv.PRIV_READ_CONSENTS_PARTNERS_DICT],
    children: [
      {
        name: "Kategorie",
        path: "/dictionaries/categorys",
        privileges: [priv.PRIV_READ_CATEGORY_DICT],
      },
      {
        name: "Kody pocztowe",
        path: "/dictionaries/postcodes",
        privileges: [priv.PRIV_READ_POSTCODES_DICT],
      },
      {
        name: "Partnerzy zgód",
        path: "/dictionaries/consents_partners_dict",
        privileges: [priv.PRIV_READ_CONSENTS_PARTNERS_DICT],
      },
    ],
  },
  {
    name: "Zgody",
    icon: "fa fa-handshake-o",
    privileges: [priv.PRIV_READ_CONSENTS, priv.PRIV_READ_CONSENTS_DICT, priv.PRIV_READ_CONSENTS_PARTNERS],
    children: [
      {
        name: "Zgody",
        path: "/consents/consents",
        privileges: [priv.PRIV_READ_CONSENTS],
      },
      {
        name: "Słownik zgód",
        path: "/consents/consents_dict",
        privileges: [priv.PRIV_READ_CONSENTS_DICT],
      },
      {
        name: "Partnerzy zgód",
        path: "/consents/consents_partners",
        privileges: [priv.PRIV_READ_CONSENTS_PARTNERS],
      },
    ],
  },
  {
    name: "Rekordy",
    icon: "fa fa-database",
    privileges: [priv.PRIV_READ_FILTER],
    children: [
      {
        name: "Walidator",
        path: "/filter/filter_analyser",
        privileges: [priv.PRIV_READ_FILTER],
      },
    ],
  },
  {
    name: "Raporty",
    icon: "nc-icon nc-single-copy-04",
    privileges: [priv.PRIV_READ_HLR, priv.PRIV_READ_SETTLEMENTS, priv.PRIV_READ_QUIZ, priv.PRIV_READ_ADMIN_STATS],
    children: [
      {
        name: "HLR/mailing",
        path: "/raports/hlr",
        privileges: [priv.PRIV_READ_HLR],
      },
      // {
      //   name: 'Rozliczenia',
      //   path: '/raports/settlements',
      //   privileges: [priv.PRIV_READ_SETTLEMENTS]
      // },
      {
        name: "Konkursy",
        path: "/raports/quiz",
        privileges: [priv.PRIV_READ_QUIZ],
      },
      {
        name: "Raport zdarzeń",
        path: "/raports/events_raport",
        privileges: [priv.PRIV_READ_ADMIN_STATS],
      },
      {
        name: "Zebrane Leady",
        path: "/raports/collected_leads",
        privileges: [priv.PRIV_COLLECTED],
      },
      {
        name: "Korejestracja Sprzedaż",
        path: "/raports/collected_co_registration",
        privileges: [priv.PRIV_COLLECTED],
      },
      {
        name: "Walidacja nie sprzedanych",
        path: "/raports/collected_not_sold",
        privileges: [priv.PRIV_COLLECTED],
      },
      {
        name: "Demografia klik i otwarć",
        path: "/raports/collected_demography_click_open",
        privileges: [priv.PRIV_COLLECTED_DEMOGRPHY],
      },
      {
        name: "Demografia leadów",
        path: "/raports/collected_demography_leads",
        privileges: [priv.PRIV_COLLECTED_DEMOGRPHY],
      },
    ],
  },
  {
    name: "Sprzedaż",
    icon: "fa fa-usd",
    privileges: [
      priv.PRIV_READ_DISPLAY_COSTS,
      priv.PRIV_READ_UPSELLING_INCOME,
      priv.PRIV_READ_CLIENT_RATES,
      priv.PRIV_READ_QUESTIONS_EFFICIENCY,
      priv.PRIV_READ_DISPLAY_EFFICIENCY,
      priv.PRIV_READ_MAILING_EFFICIENCY,
      priv.PRIV_READ_CREATIONS_EFFICIENCY,
      priv.PRIV_READ_SMS_EFFICIENCY,
      priv.PRIV_READ_STATUS_RAPORT,
      priv.PRIV_READ_SALES_RAPORT,
      priv.PRIV_READ_SALES_LIMITS,
      priv.PRIV_READ_BANNER_SALES,
      priv.PRIV_READ_MAILING_COSTS,
      priv.PRIV_READ_DEDUPLICATION,
    ],
    children: [
      {
        name: "Deduplikacja",
        path: "/sales/deduplication",
        privileges: [priv.PRIV_READ_DEDUPLICATION],
      },
      {
        name: "Koszty display",
        path: "/sales/display_costs",
        privileges: [priv.PRIV_READ_DISPLAY_COSTS],
      },
      {
        name: "Koszty mailing",
        path: "/sales/mailing_costs",
        privileges: [priv.PRIV_READ_MAILING_COSTS],
      },
      {
        name: "Przychód ruchu spadowego",
        path: "/sales/upselling_income",
        privileges: [priv.PRIV_READ_UPSELLING_INCOME],
      },
      {
        name: "Stawki leadów",
        path: "/sales/client_rates",
        privileges: [priv.PRIV_READ_CLIENT_RATES],
      },
      {
        name: "Limity sprzedaży",
        path: "/sales/sales_limits",
        privileges: [priv.PRIV_READ_SALES_LIMITS],
      },
      {
        name: "Efektywność pytań",
        path: "/sales/questions_efficiency",
        privileges: [priv.PRIV_READ_QUESTIONS_EFFICIENCY],
      },
      {
        name: "Efektywność display",
        path: "/sales/display_efficiency",
        privileges: [priv.PRIV_READ_DISPLAY_EFFICIENCY],
      },
      {
        name: "Efektywność mailing",
        path: "/sales/mailing_efficiency",
        privileges: [priv.PRIV_READ_MAILING_EFFICIENCY],
      },
      {
        name: "Efektywność mailing stałych",
        path: "/sales/mailing_efficiency_const",
        privileges: [priv.PRIV_READ_MAILING_EFFINCIENCY_CONST],
      },
      {
        name: "Efektywność banerów",
        path: "/sales/baners_efficiency",
        privileges: [priv.PRIV_READ_BANERS_EFFICIENCY],
      },
      {
        name: "Efektywność kreacji",
        path: "/sales/creations_efficiency",
        privileges: [priv.PRIV_READ_CREATIONS_EFFICIENCY],
      },
      {
        name: "Efektywność sms",
        path: "/sales/sms_efficiency",
        privileges: [priv.PRIV_READ_SMS_EFFICIENCY],
      },
      {
        name: "Efektywność Klientów",
        path: "/sales/customer_efficiency",
        privileges: [priv.PRIV_READ_CUSTOMER_EFFICIENCY],
      },
      {
        name: "Raport statusowy",
        path: "/sales/status_raport",
        privileges: [priv.PRIV_READ_STATUS_RAPORT],
      },
      {
        name: "Raport sprzedażowy",
        path: "/sales/sales_raport",
        privileges: [priv.PRIV_READ_SALES_RAPORT],
      },
      {
        name: "Użytkownicy z reklamacji",
        path: "/sales/user_from_complaints",
        privileges: [priv.PRIV_READ_SALES_RAPORT],
      },
    ],
  },
  {
    name: "Reklamacje",
    icon: "fa fa-address-card-o",
    privileges: [
      priv.PRIV_READ_COMPLAINTS,
      priv.PRIV_READ_USER_DATA,
      priv.PRIV_READ_USER_HISTORY,
      priv.PRIV_READ_USER_MAILINGS,
      priv.PRIV_EDIT_COMPLAINTS,
      priv.PRIV_EDIT_USER_BLACKLIST,
      priv.PRIV_READ_PHONE_HISTORY,
    ],
    children: [
      {
        name: "Pulpit",
        path: "/complaints/dashboard",
        privileges: [priv.PRIV_READ_COMPLAINTS],
      },
      {
        name: "Reklamacje",
        path: "/complaints/complaints",
        privileges: [priv.PRIV_READ_COMPLAINTS],
      },
      {
        name: "Dane użytkownika",
        path: "/complaints/user_data",
        privileges: [priv.PRIV_READ_USER_DATA],
      },
      {
        name: "Historia użytkownika",
        path: "/complaints/user_history",
        privileges: [priv.PRIV_READ_USER_HISTORY],
      },
      {
        name: "Historia numeru",
        path: "/complaints/phone_history",
        privileges: [priv.PRIV_READ_PHONE_HISTORY],
      },
      {
        name: "Mailingi użytkownika",
        path: "/complaints/user_mailings",
        privileges: [priv.PRIV_READ_USER_MAILINGS],
      },
      {
        name: "Usuń użytkownika",
        path: "/complaints/user_blacklist",
        privileges: [priv.PRIV_EDIT_USER_BLACKLIST],
      },
      {
        name: "Szablony odpowiedzi",
        path: "/complaints/mailbox_templates",
        privileges: [priv.PRIV_EDIT_COMPLAINTS],
      },
    ],
  },
  {
    name: "Statystyki",
    icon: "nc-icon nc-chart-bar-32",
    privileges: [priv.PRIV_READ_ADMIN_STATS, priv.PRIV_READ_MAILING_STATS, priv.PRIV_READ_CONVERSION_STATS, priv.PRIV_READ_QUESTION_API_STATS],
    children: [
      {
        name: "Wyświetlenia pytań",
        path: "/stats/display_stats",
        privileges: [priv.PRIV_READ_ADMIN_STATS],
      },
      {
        name: "Aktywność pytań",
        path: "/stats/question_activity",
        privileges: [priv.PRIV_READ_ADMIN_STATS],
      },
      {
        name: "Statystyki Leadów",
        path: "/stats/lead_stats",
        privileges: [priv.PRIV_READ_ADMIN_STATS],
      },
      {
        name: "Statystyki API Pytań",
        path: "/stats/question_api_stats",
        privileges: [priv.PRIV_READ_QUESTION_API_STATS],
      },
      {
        name: "Statystki mailingów",
        path: "/stats/mailing_stats",
        privileges: [priv.PRIV_READ_MAILING_STATS],
      },{
        name: "Statystki banerów",
        path: "/stats/baners_stats",
        privileges: [priv.PRIV_READ_BANERS_STATS],
      },
      {
        name: "Konwersja",
        path: "/stats/conversion_stats",
        privileges: [priv.PRIV_READ_CONVERSION_STATS],
      },
      {
        name: "Demografia",
        path: "/stats/demography",
        privileges: [priv.PRIV_READ_ADMIN_STATS,priv.PRIV_COLLECTED_DEMOGRPHY],
      },
    ],
  },
  {
    name: "Testy",
    icon: "fa fa-flask",
    privileges: [priv.PRIV_READ_TESTS_RESULTS],
    children: [
      {
        name: "Wyniki testów",
        path: "/tests/tests_results",
        privileges: [priv.PRIV_READ_TESTS_RESULTS],
      },
      {
        name: "Wyniki szczegółowe",
        path: "/tests/tests_results_detailed",
        privileges: [priv.PRIV_READ_TESTS_RESULTS],
      },
      {
        name: "Zlecenia testów",
        path: "/tests/tests_requests",
        privileges: [priv.PRIV_READ_TESTS_RESULTS],
      },
      {
        name: "Konfiguracja Hotjar",
        path: "/tests/hotjar_config",
        privileges: [priv.PRIV_READ_HOTJAR_CONFIG],
      },
    ],
  },
  {
    name: "Mailingi",
    icon: "nc-icon nc-email-85",
    privileges: [priv.PRIV_MAILINGS_SEND, priv.PRIV_READ_PBR],
    children: [
      {
        name: "PBR",
        path: "/mailings/pbr",
        privileges: [priv.PRIV_READ_PBR],
      },
      {
        name: "Mailingi-s",
        path: "/mailings/mMain",
        privileges: [priv.PRIV_MAILINGS_SEND],
      },
      {
        name: "Listy Mailingowe",
        path: "/mailings/lists_mails",
        privileges: [priv.PRIV_MAILINGS_SEND],
      },
      {
        name: "Kampanie robocze",
        path: "/mailings/work_campaigns",
        privileges: [priv.PRIV_MAILINGS_SEND],
      },
      {
        name: "Kampanie oczekujące",
        path: "/mailings/wait_campaigns",
        privileges: [priv.PRIV_MAILINGS_SEND],
      },
      {
        name: "Kampanie wysłane",
        path: "/mailings/sended_campaigns",
        privileges: [priv.PRIV_MAILINGS_SEND],
      },
    ],
  },
  {
    name: "Cfg Project",
    icon: "nc-icon nc-settings-gear-65",
    privileges: [priv.PRIV_READ_PID_CONFIG],
    children: [
      {
        name: "Projekt Wersje",
        path: "/pidcfg/pid_version",
        privileges: [priv.PRIV_READ_PID_CONFIG],
      },
    ],
  },
  {
    name: "Użytkownik",
    icon: "nc-icon nc-single-02",
    children: [
      {
        name: "Profil",
        path: "/user/profile",
      },
      {
        name: "Ustawienia",
        path: "/user/profile_prefs",
      },
      {
        name: "Zmień hasło",
        path: "/user/password",
      },
      {
        name: "Wyloguj",
        path: "/logout",
      },
    ],
  },
];
