import {MAILING_EFFINCIENCY_CONST_LOAD} from "@/store/actions/secure/mailing_efficiency_const";

export const PRIV_READ_CAMPAIGNS_STATS = 1;
export const PRIV_READ_DATABASE_STATS = 2;
export const PRIV_READ_CAMPAIGNS = 3;
export const PRIV_READ_SCENARIOS = 4;
export const PRIV_READ_SMS_CREATIONS = 5;
export const PRIV_READ_TRIGGERS = 7;
export const PRIV_READ_PARTNERS = 8;
export const PRIV_READ_USERS = 9;
export const PRIV_READ_PERMISSIONS = 10;
export const PRIV_READ_ROLES = 11;
export const PRIV_READ_GLOBAL_LIMITS = 12;
export const PRIV_EDIT_USERS = 13;
export const PRIV_EDIT_CAMPAIGNS = 14;
export const PRIV_EDIT_PARTNERS = 15;
export const PRIV_EDIT_SCENARIOS = 16;
export const PRIV_EDIT_SMS_CREATIONS = 17;
export const PRIV_READ_BANNERS = 18;
export const PRIV_EDIT_BANNERS = 19;
export const PRIV_READ_PIXELS = 20;
export const PRIV_EDIT_PIXELS = 21;
export const PRIV_EDIT_TRIGGERS = 22;
export const PRIV_READ_TRIGGER_TYPES = 23;
export const PRIV_EDIT_TRIGGER_TYPES = 24;
export const PRIV_READ_ADMIN = 25;
export const PRIV_EDIT_ADMIN = 26;
export const PRIV_READ_ADMIN_STATS = 27;
export const PRIV_DELETE_CAMPAIGNS = 28;
export const PRIV_DELETE_SCENARIOS = 29;
export const PRIV_READ_EMAIL_CREATIONS = 30;
export const PRIV_EDIT_EMAIL_CREATIONS = 31;
export const PRIV_DELETE_EMAIL_CREATIONS = 32;
export const PRIV_DELETE_SMS_CREATIONS = 33;
export const PRIV_DELETE_TRIGGERS = 34;
export const PRIV_READ_DATABASES_GLOBAL = 35;
export const PRIV_EDIT_DATABASES_GLOBAL = 36;
export const PRIV_READ_DATABASES_EXCEPTIONS = 37;
export const PRIV_EDIT_DATABASES_EXCEPTIONS = 38;
export const PRIV_DELETE_DATABASES_EXCEPTIONS = 39;
export const PRIV_READ_ADMIN_QUESTIONS = 40;
export const PRIV_EDIT_ADMIN_QUESTIONS = 41;
export const PRIV_READ_ADMIN_QUESTIONS_CONFIG = 42;
export const PRIV_EDIT_ADMIN_QUESTIONS_CONFIG = 43;
export const PRIV_DELETE_ADMIN_QUESTIONS_CONFIG = 44;
export const PRIV_READ_SETTLEMENTS = 45;
export const PRIV_READ_QUIZ = 46;
export const PRIV_READ_LEADS = 47;
export const PRIV_EDIT_LEADS = 48;
export const PRIV_READ_CATEGORY_DICT = 49;
export const PRIV_EDIT_CATEGORY_DICT = 50;
export const PRIV_READ_POSTCODES_DICT = 51;
export const PRIV_EDIT_POSTCODES_DICT = 52;
export const PRIV_READ_HLR = 53;
export const PRIV_READ_PRIZES = 54;
export const PRIV_EDIT_PRIZES = 55;
export const PRIV_READ_DISPLAY_COSTS = 56;
export const PRIV_EDIT_DISPLAY_COSTS = 57;
export const PRIV_DELETE_DISPLAY_COSTS = 58;
export const PRIV_READ_EMAIL_TEST = 59;
export const PRIV_EDIT_EMAIL_TEST = 60;
export const PRIV_READ_CLIENT_RATES = 61;
export const PRIV_EDIT_CLIENT_RATES = 62;
export const PRIV_READ_DISPLAY_EFFICIENCY = 63;
export const PRIV_READ_MAILING_EFFICIENCY = 64;
export const PRIV_READ_SMS_EFFICIENCY = 65;
export const PRIV_READ_STATUS_RAPORT = 66;
export const PRIV_READ_UPSELLING_INCOME = 67;
export const PRIV_EDIT_UPSELLING_INCOME = 68;
export const PRIV_DELETE_UPSELLING_INCOME = 69;
export const PRIV_READ_SALES_RAPORT = 70;
export const PRIV_READ_MAILING_STATS = 71;
export const PRIV_READ_CREATIONS_EFFICIENCY = 84;
export const PRIV_READ_QUESTION_GROUP = 87;
export const PRIV_EDIT_QUESTION_GROUP = 88;
export const PRIV_READ_QUESTIONS_EFFICIENCY = 89;
export const PRIV_READ_CONSENTS = 92;
export const PRIV_EDIT_CONSENTS = 93;
export const PRIV_READ_CONSENTS_DICT = 94;
export const PRIV_EDIT_CONSENTS_DICT = 95;
export const PRIV_READ_CONSENTS_PARTNERS_DICT = 96;
export const PRIV_EDIT_CONSENTS_PARTNERS_DICT = 97;
export const PRIV_READ_CONSENTS_PARTNERS = 98;
export const PRIV_EDIT_CONSENTS_PARTNERS = 99;
export const PRIV_READ_FILTER = 100;
export const PRIV_READ_SALES_LIMITS = 101;
export const PRIV_EDIT_SALES_LIMITS = 102;
export const PRIV_READ_PROJECTS = 103;
export const PRIV_READ_BANNER_SALES = 104;
export const PRIV_READ_TESTS_RESULTS = 105;
export const PRIV_READ_TESTS_REQUESTS = 107;
export const PRIV_EDIT_TESTS_REQUESTS = 108;
export const PRIV_READ_HOTJAR_CONFIG = 110;
export const PRIV_EDIT_HOTJAR_CONFIG = 111;
export const PRIV_DELETE_HOTJAR_CONFIG = 112;
export const PRIV_READ_CONVERSION_STATS = 113;
export const PRIV_READ_QUESTION_LIST = 114;
export const PRIV_READ_CLIENT_DICT = 115;
export const PRIV_READ_MEDIA_HOUSE_DICT = 116;
export const PRIV_READ_BANNER_DICT = 117;
export const PRIV_READ_COMPLAINTS = 118;
export const PRIV_READ_PARTNERS_DICT = 119;
export const PRIV_READ_USERS_DICT = 120;
export const PRIV_EDIT_COMPLAINTS = 121;
export const PRIV_READ_USER_DATA = 122;
export const PRIV_READ_USER_HISTORY = 123;
export const PRIV_READ_USER_MAILINGS = 124;
export const PRIV_EDIT_USER_BLACKLIST = 125;
export const PRIV_READ_MAILING_COSTS = 126;
export const PRIV_EDIT_MAILING_COST = 127;
export const PRIV_DELETE_MAILING_COSTS = 128;
export const PRIV_READ_COMPANY = 129;
export const PRIV_READ_QUESTION_API_STATS = 130;
export const PRIV_READ_PBR = 145;
export const PRIV_EDIT_PBR = 146;
export const PRIV_MAILINGS_SEND = 147;
export const PRIV_EDIT_MAILINGS_SEND = 148;
export const PRIV_READ_PHONE_HISTORY = 153;
export const PRIV_READ_DEDUPLICATION = 154;
export const PRIV_EDIT_DEDUPLICATION = 155;
export const PRIV_READ_CUSTOMER_EFFICIENCY = 156;
export const PRIV_READ_BANERS_STATS = 157;
export const PRIV_READ_PID_CONFIG = 158;
export const PRIV_READ_KO_QUESTION = 159;
export const PRIV_EDIT_KOSENDS = 160;
export const PRIV_READ_KOSENDS = 161;
export const PRIV_READ_BANERS_EFFICIENCY = 162;

export const PRIV_READ_MAILING_EFFINCIENCY_CONST= 223;
export const PRIV_READ_MAILING_SENDED_AMOUNT= 238;
export const PRIV_COLLECTED= 244;
export const PRIV_COLLECTED_DEMOGRPHY= 245;
