import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  BASE_API
} from 'src/store/config'


import {
  COLLECTED_CO_REGISTRATION_INVALID,
  COLLECTED_CO_REGISTRATION_LOADING, COLLECTED_CO_REGISTRATION_LOADING_ERROR,
  COLLECTED_CO_REGISTRATION_LOADING_SUCCESS, COLLECTED_CO_REGISTRATION_SAVING, COLLECTED_CO_REGISTRATION_SAVING_ERROR, COLLECTED_CO_REGISTRATION_SAVING_SUCCESS,
  LOAD_COLLECTED_CO_REGISTRATION, SAVE_COLLECTED_CO_REGISTRATION,
} from "@/store/actions/raports/collected_co_registration";

const storeObjectConfig = {
  objectName: "CollectedCoRegistration",
  getterName: "getCollectedCoRegistration",
  loadActionName: LOAD_COLLECTED_CO_REGISTRATION,
  loadingMutationName: COLLECTED_CO_REGISTRATION_LOADING,
  loadingSuccessMutationName: COLLECTED_CO_REGISTRATION_LOADING_SUCCESS,
  loadingErrorMutationName: COLLECTED_CO_REGISTRATION_LOADING_ERROR,
  invalidMutationName: COLLECTED_CO_REGISTRATION_INVALID,
  saveActionName: SAVE_COLLECTED_CO_REGISTRATION,
  savingMutationName: COLLECTED_CO_REGISTRATION_SAVING,
  savingSuccessMutationName: COLLECTED_CO_REGISTRATION_SAVING_SUCCESS,
  savingErrorMutationName: COLLECTED_CO_REGISTRATION_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/collected/co_registration",
    method: "get" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
