<template>
  <div>
    <label>Projekt</label>
    <fg-input>
      <el-select
        v-on:clear="clearVersions()"
        v-on:remove-tag="removeVersion($event)"
        v-model="projects"
        name="projects"
        filterable
        clearable
        multiple
      >
        <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
          <el-option
            v-for="item in group.options"
            :key="item.project_id"
            :label="item.project_name + ' [' + item.project_id + ']'"
            :value="item.project_id"
          >
          </el-option>
        </el-option-group>
      </el-select>
    </fg-input>
  </div>
</template>
<script>


import {Option, OptionGroup, Select} from "element-ui";
import {eventBus} from "@/main";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "@/store/actions/questions/project_dict";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
  },
  data() {
    return {
      projects: [],
      versions: [],
      versionProjectObject: {},
    };
  },
  computed: {
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);

      }
      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    clearVersions() {
      this.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.versions = filteredVersions;
    },

  },
  mounted() {
  },
  watch: {
    projects: function() {
      // Emit this information to the parents component
      this.$emit("projects", this.projects);
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {

  },
};

</script>
