export const LOAD_COLLECTED_CO_REGISTRATION = 'LOAD_COLLECTED_CO_REGISTRATION'
export const COLLECTED_CO_REGISTRATION_LOADING = 'COLLECTED_CO_REGISTRATION_LOADING'
export const COLLECTED_CO_REGISTRATION_LOADING_SUCCESS = 'COLLECTED_CO_REGISTRATION_LOADING_SUCCESS'
export const COLLECTED_CO_REGISTRATION_LOADING_ERROR = 'COLLECTED_CO_REGISTRATION_LOADING_ERROR'
export const COLLECTED_CO_REGISTRATION_INVALID = 'COLLECTED_CO_REGISTRATION_INVALID'

export const SAVE_COLLECTED_CO_REGISTRATION = 'SAVE_COLLECTED_CO_REGISTRATION'
export const COLLECTED_CO_REGISTRATION_SAVING = 'COLLECTED_CO_REGISTRATION_SAVING'
export const COLLECTED_CO_REGISTRATION_SAVING_SUCCESS = 'COLLECTED_CO_REGISTRATION_SAVING_SUCCESS'
export const COLLECTED_CO_REGISTRATION_SAVING_ERROR = 'COLLECTED_CO_REGISTRATION_SAVING_ERROR'
