<template>
  <div>
    <label>Baner</label>
    <fg-input>
      <el-select
        v-model="banners"
        name="banners"
        filterable
        remote
        reserve-keyword
        clearable
        multiple
        :remote-method="remoteSearch"
        :loading="loading"
      >
        <el-option
          v-for="banner in bannerConfig"
          :label="'[' + banner.BannerID + '] ' + banner.BannerName"
          :value="banner.BannerID"
          :key="banner.BannerID"
        ></el-option>
      </el-select>
    </fg-input>
  </div>
</template>
<script>


import {Option, Select} from "element-ui";
import {eventBus} from "@/main";
import {BANNER_CONFIG_INVALID, LOAD_BANNER_CONFIG} from "@/store/actions/stats/banner_config";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: false,
      banners:[],
    };
  },
  computed: {
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
  },
  methods: {
    async loadData(force) {

    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, {searchQuery}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
  },
  mounted() {
  },
  watch: {
    banners: function() {
      // Emit this information to the parents component
      this.$emit("banners", this.banners);
    }
  },
  created() {
  },
  beforeDestroy: function () {

  },
};

</script>
