<template>
  <div>
    <label>Wydawca</label>
    <fg-input>
      <el-select v-model="media_house" name="media_house" filterable clearable>
        <el-option v-for="item in mediaHouse" :label="'[' + item.ID + '] ' + item.Name" :value="item.ID"
                   :key="item.ID"></el-option>
      </el-select>
    </fg-input>
  </div>
</template>
<script>


import {Option, Select} from "element-ui";
import {eventBus} from "@/main";
import {LOAD_MEDIA_HOUSE, MEDIA_HOUSE_INVALID} from "@/store/actions/stats/media_house";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      media_house:[],
    };
  },
  computed: {
    mediaHouse() {
      let data = Object.values(this.$store.getters.getMediaHouse);
      data.sort((a, b) => b.ID - a.ID);
      return data;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(MEDIA_HOUSE_INVALID);

      }
      await this.$store.dispatch(LOAD_MEDIA_HOUSE, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
  },
  mounted() {
  },
  watch: {
    media_house: function() {
      // Emit this information to the parents component
      this.$emit("media_house", this.media_house);
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {

  },
};

</script>
