export const LOAD_COLLECTED_LEADS_EXCEL = 'LOAD_COLLECTED_LEADS_EXCEL'
export const COLLECTED_LEADS_EXCEL_LOADING = 'COLLECTED_LEADS_EXCEL_LOADING'
export const COLLECTED_LEADS_EXCEL_LOADING_SUCCESS = 'COLLECTED_LEADS_EXCEL_LOADING_SUCCESS'
export const COLLECTED_LEADS_EXCEL_LOADING_ERROR = 'COLLECTED_LEADS_EXCEL_LOADING_ERROR'
export const COLLECTED_LEADS_EXCEL_INVALID = 'COLLECTED_LEADS_EXCEL_INVALID'

export const SAVE_COLLECTED_LEADS_EXCEL = 'SAVE_COLLECTED_LEADS_EXCEL'
export const COLLECTED_LEADS_EXCEL_SAVING = 'COLLECTED_LEADS_EXCEL_SAVING'
export const COLLECTED_LEADS_EXCEL_SAVING_SUCCESS = 'COLLECTED_LEADS_EXCEL_SAVING_SUCCESS'
export const COLLECTED_LEADS_EXCEL_SAVING_ERROR = 'COLLECTED_LEADS_EXCEL_SAVING_ERROR'
