export const LOAD_COLLECTED_LEADS = 'LOAD_COLLECTED_LEADS'
export const COLLECTED_LEADS_LOADING = 'COLLECTED_LEADS_LOADING'
export const COLLECTED_LEADS_LOADING_SUCCESS = 'COLLECTED_LEADS_LOADING_SUCCESS'
export const COLLECTED_LEADS_LOADING_ERROR = 'COLLECTED_LEADS_LOADING_ERROR'
export const COLLECTED_LEADS_INVALID = 'COLLECTED_LEADS_INVALID'

export const SAVE_COLLECTED_LEADS = 'SAVE_COLLECTED_LEADS'
export const COLLECTED_LEADS_SAVING = 'COLLECTED_LEADS_SAVING'
export const COLLECTED_LEADS_SAVING_SUCCESS = 'COLLECTED_LEADS_SAVING_SUCCESS'
export const COLLECTED_LEADS_SAVING_ERROR = 'COLLECTED_LEADS_SAVING_ERROR'
