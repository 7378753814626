import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  BASE_API
} from 'src/store/config'


import {
  COLLECTED_DEMOGRAPHY_LEADS_EXCEL_INVALID,
  COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING, COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING_ERROR,
  COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING_SUCCESS, COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING, COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING_ERROR, COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING_SUCCESS,
  LOAD_COLLECTED_DEMOGRAPHY_LEADS_EXCEL, SAVE_COLLECTED_DEMOGRAPHY_LEADS_EXCEL,
} from "@/store/actions/raports/collected_demography_leads_excel";

const storeObjectConfig = {
  objectName: "CollectedDemographyLeadsExcel",
  getterName: "getCollectedDemographyLeadsExcel",
  loadActionName: LOAD_COLLECTED_DEMOGRAPHY_LEADS_EXCEL,
  loadingMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING,
  loadingSuccessMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING_SUCCESS,
  loadingErrorMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_LOADING_ERROR,
  invalidMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_INVALID,
  saveActionName: SAVE_COLLECTED_DEMOGRAPHY_LEADS_EXCEL,
  savingMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING,
  savingSuccessMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING_SUCCESS,
  savingErrorMutationName: COLLECTED_DEMOGRAPHY_LEADS_EXCEL_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/collected/demography_leads_excel",
    method: "get" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
