export const LOAD_COLLECTED_NOT_SOLD = 'LOAD_COLLECTED_NOT_SOLD'
export const COLLECTED_NOT_SOLD_LOADING = 'COLLECTED_NOT_SOLD_LOADING'
export const COLLECTED_NOT_SOLD_LOADING_SUCCESS = 'COLLECTED_NOT_SOLD_LOADING_SUCCESS'
export const COLLECTED_NOT_SOLD_LOADING_ERROR = 'COLLECTED_NOT_SOLD_LOADING_ERROR'
export const COLLECTED_NOT_SOLD_INVALID = 'COLLECTED_NOT_SOLD_INVALID'

export const SAVE_COLLECTED_NOT_SOLD = 'SAVE_COLLECTED_NOT_SOLD'
export const COLLECTED_NOT_SOLD_SAVING = 'COLLECTED_NOT_SOLD_SAVING'
export const COLLECTED_NOT_SOLD_SAVING_SUCCESS = 'COLLECTED_NOT_SOLD_SAVING_SUCCESS'
export const COLLECTED_NOT_SOLD_SAVING_ERROR = 'COLLECTED_NOT_SOLD_SAVING_ERROR'
