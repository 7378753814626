import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  BASE_API
} from 'src/store/config'


import {
  COLLECTED_DEMOGRAPHY_CLICK_OPEN_INVALID,
  COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING, COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_ERROR,
  COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_SUCCESS, COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING, COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_ERROR, COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_SUCCESS,
  LOAD_COLLECTED_DEMOGRAPHY_CLICK_OPEN, SAVE_COLLECTED_DEMOGRAPHY_CLICK_OPEN,
} from "@/store/actions/raports/collected_demography_click_open";

const storeObjectConfig = {
  objectName: "CollectedDemographyClickOpen",
  getterName: "getCollectedDemographyClickOpen",
  loadActionName: LOAD_COLLECTED_DEMOGRAPHY_CLICK_OPEN,
  loadingMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING,
  loadingSuccessMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_SUCCESS,
  loadingErrorMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_ERROR,
  invalidMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_INVALID,
  saveActionName: SAVE_COLLECTED_DEMOGRAPHY_CLICK_OPEN,
  savingMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING,
  savingSuccessMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_SUCCESS,
  savingErrorMutationName: COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/collected/demography_click_open",
    method: "get" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
