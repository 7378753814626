export const LOAD_COLLECTED_DEMOGRAPHY_CLICK_OPEN = 'LOAD_COLLECTED_DEMOGRAPHY_CLICK_OPEN'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_SUCCESS = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_SUCCESS'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_ERROR = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_LOADING_ERROR'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_INVALID = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_INVALID'

export const SAVE_COLLECTED_DEMOGRAPHY_CLICK_OPEN = 'SAVE_COLLECTED_DEMOGRAPHY_CLICK_OPEN'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_SUCCESS = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_SUCCESS'
export const COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_ERROR = 'COLLECTED_DEMOGRAPHY_CLICK_OPEN_SAVING_ERROR'
