<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Korejestracja Sprzedaż</h5>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-8 col-lg-6  text-md-right">
            <label>Data zebrania leada</label><br>
            <el-date-picker
              v-model="dateRange"
              type="datetimerange"
              align="center"
              unlink-panels
              range-separator="-"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-mh-dict-view @media_house="changeMh"></app-mh-dict-view>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-banner-search-view @banners="changeBanners"></app-banner-search-view>
          </div>
          <div class="offset-md-4 offset-lg-3 col-md-4 col-lg-3 text-right">
            <app-project-dict-view @projects="changeProjects"></app-project-dict-view>
          </div>
          <div class="col-md-6 text-right mt-3">
            <p-button type="success" @click.prevent="loadData()">Wczytaj</p-button>
            <p-button type="success" style="margin-left: 5px;" @click.prevent="loadData('excel')">Excel</p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table :data="coRegistrationDataDisplay" style="width: 100%" @sort-change="sortChange">
          <el-table-column label="Wydawca" property="Wydawca" width="150" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="WydawcaId" width="150" property="WydawcaId" sortable="custom"></el-table-column>
          <el-table-column label="BannerId" width="150" property="BannerId" sortable="custom"></el-table-column>
          <el-table-column label="BannerNazwa" width="170" property="BannerNazwa" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="ProjectName" width="170" property="ProjectName" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="ProjectId" width="150" property="ProjectId" sortable="custom"></el-table-column>
          <el-table-column label="Modul" width="150" property="Modul" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="WalidacjaReczna" width="200" property="WalidacjaReczna" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="Kanal" width="150" property="Kanal" sortable="custom"></el-table-column>
          <el-table-column label="KlientId" width="150" property="KlientId" sortable="custom"></el-table-column>
          <el-table-column label="KlientNazwa" width="170" property="KlientNazwa" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="ileLeadow" width="150" property="ileLeadow" sortable="custom"></el-table-column>
          <el-table-column label="DataRejestracji" width="190" property="DataRejestracji" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="TypBannera" width="150" property="TypBannera" sortable="custom" class-name="strSort"></el-table-column>
          <el-table-column label="isNewLead" width="150" property="isNewLead" sortable="custom"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="card-body row">
      <div class="col-sm-6 pagination-info">
        <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                      :total="pagination.total"></p-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import utils from "src/utils";

// event bus
import {eventBus} from "src/main";

import {Select, DatePicker, Pagination, Option, OptionGroup} from "element-ui";
import ProjectDictView from "@/components/Utils4/formsElementView/projectDict.vue";
import BannerSearchView from "@/components/Utils4/formsElementView/bnnerSearch.vue"
import MhDictView from "@/components/Utils4/formsElementView/mhDict.vue";


import {
  LOAD_COLLECTED_CO_REGISTRATION,
  COLLECTED_CO_REGISTRATION_INVALID
} from "@/store/actions/raports/collected_co_registration"
import {
  LOAD_COLLECTED_CO_REGISTRATION_EXCEL,
  COLLECTED_CO_REGISTRATION_EXCEL_INVALID
} from "@/store/actions/raports/collected_co_registration_excel";
import PPagination from "@/components/UIComponents/Pagination.vue";
import collectedCoRegistration from "@/store/modules/raports/collected_co_registration";


export default {
  components: {
    PPagination,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [Pagination.name]: Pagination,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    appProjectDictView: ProjectDictView,
    appBannerSearchView: BannerSearchView,
    appMhDictView: MhDictView,
  },
  data() {
    return {
      loading: false,
      listCoRegistrationPresent:[],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      filter: {
        media_house: null,
        banners: [],
        projects: [],
      },
      dateRange: [moment(utils.today()).format("YYYY-MM-DD 00:00:00"), moment(utils.today()).format("YYYY-MM-DD 23:59:59")],
      pickerOptions: {
        selectableRange: '18:30:00 - 20:30:00',
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async loadData(mode) {
      this.listCoRegistrationPresent = [];
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
        baners: this.filter.banners,
        mediahouse: this.filter.media_house,
        projects: this.filter.projects
      };

      this.$store.commit(mode === "excel" ? COLLECTED_CO_REGISTRATION_EXCEL_INVALID : COLLECTED_CO_REGISTRATION_INVALID);

      await this.$store
        .dispatch(mode === "excel" ? LOAD_COLLECTED_CO_REGISTRATION_EXCEL : LOAD_COLLECTED_CO_REGISTRATION, filters)
        .then(() => {

          let message = 'Wczytano dane';
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        })
        .catch((error) => {
          let isJson;
          let msg = "";
          let err_msg;
          try {
            isJson = true;
            err_msg = JSON.parse(error.response.data.description);
          } catch (e) {
            isJson = false
          }
          if (isJson === true) {
            if ("missing" in err_msg) {
              msg = "Brak parametru: " + err_msg.missing;
            }
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getCollectedCoRegistrationExcel.tmpname;

        const fileName = this.$store.getters.getCollectedCoRegistrationExcel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-utils4.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.slice(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    changeProjects(projects) {
      this.filter.projects = projects;
    },
    changeBanners(banners) {
      this.filter.banners = banners;
    },
    changeMh(media_house) {
      this.filter.media_house = media_house;
    },
    sortChange(obj) {
      let result = this.coRegistrationPresent;

      let sorStr = false;
      if (obj.column.hasOwnProperty('className')) {
        sorStr = obj.column.className.includes('strSort');
      }
      if (obj.order === "descending") {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        if (sorStr) {
          result.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          result.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      }
      this.coRegistrationPresent = result;
    },
  },
  computed: {

    coRegistrationData() {
      let result = [];
      if (this.listCoRegistrationPresent.length === 0 && this.$store.state.collectedCoRegistration.loading_status === "success") {
        this.listCoRegistrationPresent = this.$store.getters.getCollectedCoRegistration;
      }
      return this.listCoRegistrationPresent;
    },
    coRegistrationPresent: {
      get()
      {
        let result = this.coRegistrationData;
        if (Array.isArray(result)) {
          this.pagination.total = result.length;
          return result.slice(this.from, this.to);
        } else {
          result = [];
        }
        return result;
      },
      set(newValue)
      {
        this.listCoRegistrationPresent = newValue;
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.coRegistrationData.length;
      return this.coRegistrationData.length;
    },
    coRegistrationDataDisplay() {
      return this.coRegistrationPresent.slice(this.from, this.to);
    },
  },
  created() {
  },

  beforeDestroy: function () {
    this.$store.commit(COLLECTED_CO_REGISTRATION_INVALID);
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}
</style>
